var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{attrs:{"fluid":""}},[_c('v-row',[_c('v-col',{staticClass:"pt-0 d-flex align-center flex-column",attrs:{"cols":"12"}},[_c('div',{staticClass:"projectName "},[_vm._v(_vm._s(_vm.projectName))]),_c('div',{staticClass:"yellowLine"})]),_c('v-col',{attrs:{"cols":"12"}},[_c('div',{staticClass:"conOfTabs"},[(_vm.user.children_id !== 8 && _vm.user.children_id !== 5 && _vm.user.children_id !== 6)?_c('v-btn',{staticClass:"eachBtnEl",attrs:{"text":"","to":{
            name: 'PurchasingAllRequests',
            params: { id: _vm.$route.params.id },
          },"ripple":false}},[_vm._v(" Purchasing "),_c('span',{staticClass:"conOfTotal"},[_vm._v(_vm._s(_vm.listData.requests_purchasing))])]):_vm._e(),(_vm.user.children_id !== 7 && _vm.user.children_id !== 8 && _vm.user.children_id !== 6)?_c('v-btn',{staticClass:"eachBtnEl",attrs:{"text":"","to":{
            name: 'printingAllRequests',
            params: { id: _vm.$route.params.id },
          },"ripple":false}},[_vm._v(" Printing "),_c('span',{staticClass:"conOfTotal"},[_vm._v(_vm._s(_vm.listData.requests_printing))])]):_vm._e(),(_vm.user.children_id !== 7 && _vm.user.children_id !== 5 && _vm.user.children_id !== 6)?_c('v-btn',{staticClass:"eachBtnEl",attrs:{"text":"","to":{
            name: 'productionAllRequests',
            params: { id: _vm.$route.params.id },
          },"ripple":false}},[_vm._v(" Production "),_c('span',{staticClass:"conOfTotal"},[_vm._v(_vm._s(_vm.listData.requests_production))])]):_vm._e(),(_vm.user.children_id !== 7 && _vm.user.children_id !== 5 && _vm.user.children_id !== 8)?_c('v-btn',{staticClass:"eachBtnEl",attrs:{"text":"","to":{
            name: 'photographyAllRequests',
            params: { id: _vm.$route.params.id },
          },"ripple":false}},[_vm._v(" Photography "),_c('span',{staticClass:"conOfTotal"},[_vm._v(_vm._s(_vm.listData.requests_photography))])]):_vm._e(),_c('v-btn',{staticClass:"eachBtnEl",attrs:{"text":"","to":{
            name: 'extrasAllRequests',
            params: { id: _vm.$route.params.id },
          },"ripple":false}},[_vm._v(" Extras "),_c('span',{staticClass:"conOfTotal"},[_vm._v(_vm._s(_vm.listData.requests_extras))])])],1)])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }