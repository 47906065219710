export default {
  data: () => ({
    statusList: [
      {
        id: "1",
        name: "Request Created",
      },
      {
        id: "6",
        name: "Sales Approve",
      },
      {
        id: "7",
        name: "Request Have Job Order",
      },
      {
        id: "0",
        name: "Request Canceled",
      },
      {
        id: "2",
        name: "Cost Created",
      },
      {
        id: "3",
        name: "Procurement Reject",
      },
      {
        id: "4",
        name: "Procurement Approved",
      },
      {
        id: "5",
        name: "Sales Reject",
      },
    ],
  }),
};
